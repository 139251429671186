import { FluidMovementSummary, FluidMovementView } from 'models';
import { API_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';

const fluidsMovementsUrl = `${API_URL}fluid-movements`;
const pendingMovementsUrl = `${API_URL}td-receptions`;

// GET
const getFluidMovement: (movementId: string) => Promise<FluidMovementView> = (movementId) => RequestHelper.GET({ url: `${fluidsMovementsUrl}/${movementId}` });

const getBsd = (hashId: string) => RequestHelper.GET({ url: `${fluidsMovementsUrl}/${hashId}/bsd` });

const getOperationOptions = () => RequestHelper.GET({ url: `${pendingMovementsUrl}/operation-codes` });

// POST
const getFluidsMovementsList: (
  params,
  page: number
) => Promise<FluidMovementSummary> = (
  params = {},
  page = 1
) => RequestHelper.POST({ url: `${fluidsMovementsUrl}/filter?page=${page}`, body: params });

const getPendingMovementsList: (
  params,
  page: number
) => Promise<FluidMovementSummary> = (
  params = {},
  page = 1
) => RequestHelper.POST({ url: `${pendingMovementsUrl}/filter?page=${page}`, body: params });

const validatePendingMovement = (hashId, params) => RequestHelper.PUT({ url: `${pendingMovementsUrl}/${hashId}/receive`, body: params });

const createFluidMovement = fluidMovement => RequestHelper.POST({ url: `${fluidsMovementsUrl}`, body: fluidMovement });

const updateFluidMovement: (
  hashId: string,
  fluidMovement: FluidMovementView
) => Promise<void> = (hashId, fluidMovement) => RequestHelper.PUT({ url: `${fluidsMovementsUrl}/${hashId}`, body: fluidMovement });

const importFluidMovement = document => RequestHelper.POST({ url: `${fluidsMovementsUrl}/import`, body: document });

const importSales = document => RequestHelper.POST({ url: `${fluidsMovementsUrl}/import/sales`, body: document });

const importPurchases = document => RequestHelper.POST({ url: `${fluidsMovementsUrl}/import/purchases`, body: document });

const exportFluidMovement = params => RequestHelper.POST({ url: `${fluidsMovementsUrl}/export`, body: params });

const exportFluidMovementByEmail = (params, signal) => RequestHelper.POST({
  url: `${fluidsMovementsUrl}/notif-export`,
  body: params,
  signal
});

// DELETE
const deleteFluidMovement = hashId => RequestHelper.DELETE({ url: `${fluidsMovementsUrl}/${hashId}` });

export const FluidMovementsService = {
  getFluidMovement,
  getBsd,
  getOperationOptions,
  getFluidsMovementsList,
  getPendingMovementsList,
  validatePendingMovement,
  createFluidMovement,
  updateFluidMovement,
  importFluidMovement,
  exportFluidMovement,
  exportFluidMovementByEmail,
  deleteFluidMovement,
  importSales,
  importPurchases
};
