import { InterventionList, InterventionView } from 'models';
import { InterventionStatusView } from 'models/detail/InterventionStatusView';
import { InterventionFilter } from 'models/request/list/InterventionFilter';
import { API_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';
import { SelectItem } from 'utils/types';

const interventionUrl = `${API_URL}interventions`;

// GET
const getIntervention: (
  hashId: string
) => Promise<InterventionView> = (
  hashId
) => RequestHelper.GET({ url: `${interventionUrl}/${hashId}` });

const getInterventionStatus: (
  hashId: string
) => Promise<InterventionStatusView> = (
  hashId
) => RequestHelper.GET({ url: `${interventionUrl}/${hashId}/status` });

const getInterventionDownload: (
  hashId: string
) => Promise<InterventionView> = (
  hashId
) => RequestHelper.GET({ url: `${interventionUrl}/${hashId}/download` });

const getOptions = () => RequestHelper.GET({ url: `${interventionUrl}/options` });

const getInterventionCerfa: (
  hashId: string
) => Promise<any> = (
  hashId
) => RequestHelper.GET({ url: `${interventionUrl}/${hashId}/cerfa` });

const getInterventionsPending: (
  deviceId: string
) => Promise<InterventionView[]> = (
  deviceId
) => RequestHelper.GET({ url: `${interventionUrl}/download-pending?deviceId=${deviceId}` });

const getInterventionStatuses: () => Promise<SelectItem[]> = () => RequestHelper.GET({ url: `${interventionUrl}/statuses` });

// POST
const checkFileNumber: (
  fileNumber: string
) => Promise<boolean> = (
  fileNumber
) => RequestHelper.POST({ url: `${interventionUrl}/check`, body: { fileNumber } });

const getList: (
  params: InterventionFilter,
  page: number
) => Promise<InterventionList> = (
  params = {},
  page = 1
) => RequestHelper.POST({ url: `${interventionUrl}/filter?page=${page}`, body: params });

const getTemporaryCerfa = (intervention: InterventionView) => RequestHelper.POST({ url: `${interventionUrl}/temporary-cerfa`, body: intervention });

const saveIntervention = (intervention: InterventionView) => RequestHelper.POST({ url: interventionUrl, body: intervention });

const getCustomFileNumber = () => RequestHelper.POST({ url: `${interventionUrl}/file-number` });

const exportInterventions = params => RequestHelper.POST({ url: `${interventionUrl}/export`, body: params });

const exportInterventionsByEmail = (params, signal) => RequestHelper.POST({
  url: `${interventionUrl}/notif-export`,
  body: params,
  signal
});

// PUT
const startIntervention = (hashId: string, intervention: InterventionView) => RequestHelper.PUT({ url: `${interventionUrl}/${hashId}/start`, body: intervention });

const planIntervention = (hashId: string, intervention: InterventionView) => RequestHelper.PUT({ url: `${interventionUrl}/${hashId}/plan`, body: intervention });

const updateIntervention = (hashId: string, intervention: InterventionView) => RequestHelper.PUT({ url: `${interventionUrl}/${hashId}`, body: intervention });

const reassignOperator = (hashId: string, operator) => RequestHelper.PUT({ url: `${interventionUrl}/${hashId}/reassign`, body: operator });

const finishIntervention = (hashId: string, intervention: InterventionView) => RequestHelper.PUT({ url: `${interventionUrl}/${hashId}/finish`, body: intervention });

// DELETE
const deleteIntervention = hashId => RequestHelper.DELETE({ url: `${interventionUrl}/${hashId}` });

export const InterventionsService = {
  getIntervention,
  getInterventionStatus,
  getInterventionDownload,
  getOptions,
  getInterventionCerfa,
  getInterventionsPending,
  getInterventionStatuses,
  checkFileNumber,
  getList,
  getTemporaryCerfa,
  saveIntervention,
  getCustomFileNumber,
  exportInterventions,
  exportInterventionsByEmail,
  startIntervention,
  planIntervention,
  updateIntervention,
  reassignOperator,
  finishIntervention,
  deleteIntervention
};
