import { UserView } from 'models';
import { RoleSelectItem } from 'models/detail/RoleSelectItem';
import { ScopeView } from 'models/detail/ScopeView';
import { API_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';
import { SelectItem } from 'utils/types';
import { StructureLevelOption } from 'utils/types/StructureLevelOption';
import { AccessibleRoleByStructureLevelView } from '../models/detail/AccessibleRoleByStructureLevelView';

const usersUrl = `${API_URL}user-accounts`;

// GET
const getCurrentUser: () => Promise<UserView> = () => RequestHelper.GET({ url: `${usersUrl}/current` });

const getCurrentLevel: () => Promise<StructureLevelOption> = () => RequestHelper.GET({ url: `${usersUrl}/current/current-level` });

const getLevelOptions: () => Promise<StructureLevelOption[]> = () => RequestHelper.GET({ url: `${usersUrl}/current/accessible-levels` });

const getUser: (id: string) => Promise<UserView> = id => RequestHelper.GET({ url: `${usersUrl}/${id}` });
const getCurrentScope: () => Promise<ScopeView> = () => RequestHelper.GET({ url: `${usersUrl}/current/scope` });

const getAccessibleRolesWithStructureLevel: (companyId: string) => Promise<AccessibleRoleByStructureLevelView[]> = (companyId) => RequestHelper.GET({
    url: `${usersUrl}/${companyId}/rolesByStructureLevel`
});

const getApplicationCount: () => Promise<{ currentUse: number; maximumAllowed: number }> = () => RequestHelper.GET({ url: `${usersUrl}/application/count` });

const getUserConfigsDeviceId: () => Promise<string> = () => RequestHelper.GET({ url: `${usersUrl}/current/device-id` });

const getCompanyChildrenOptions: () => Promise<SelectItem[]> = () => RequestHelper.GET({ url: `${usersUrl}/current/company/children-options` });

const resetPassword = () => RequestHelper.GET({ url: `${usersUrl}/resetPassword` });

// POST
const getList: (
  params: any,
  page : number
) => Promise<any> = (
  params = {},
  page = 1
) => RequestHelper.POST({ url: `${usersUrl}/filter?page=${page}`, body: params });

const createUser: (user: UserView) => Promise<UserView> = user => RequestHelper.POST({ url: `${usersUrl}`, body: user });

// const importUsers = document => RequestHelper.POST(`${usersUrl}/import`, document);

// PUT
const updateUser: (id: string, user: UserView) => Promise<any> = (id, user) => RequestHelper.PUT({ url: `${usersUrl}/${id}`, body: user });

const updateLocale: (locale: string) => Promise<any> = locale => RequestHelper.PUT({ url: `${usersUrl}/locale?locale=${locale}` });

const updateCountry: (country: string) => Promise<any> = country => RequestHelper.PUT({ url: `${usersUrl}/country?country=${country}` });

const updateRoles: (id: string, roles: RoleSelectItem[]) => Promise<any> = (id, roles) => RequestHelper.PUT({ url: `${usersUrl}/${id}/roles`, body: roles });
const setUserConfigsDeviceId: (deviceId: string) => Promise<any> = deviceId => RequestHelper.PUT({ url: `${usersUrl}/current/device-id`, body: { deviceId } });

// PATCH
const updateLevel: (level: StructureLevelOption) => Promise<any> = level => RequestHelper.PATCH({ url: `${usersUrl}/current/level`, body: level });

export const UsersService = {
  getCurrentUser,
  getCurrentLevel,
  getUser,
  getList,
  getApplicationCount,
  getLevelOptions,
  getUserConfigsDeviceId,
  getCompanyChildrenOptions,
  updateLevel,
  createUser,
  updateUser,
  updateLocale,
  updateCountry,
  setUserConfigsDeviceId,
  updateRoles,
  getCurrentScope,
  resetPassword,
  getAccessibleRolesWithStructureLevel
};
