import { API_URL } from '../utils/constants';
import { RequestHelper } from '../utils/helpers';

const translationsUrl = `${API_URL}translations`;

const getLabels = (application, locale) => RequestHelper.GET({
  url: `${translationsUrl}/front-labels?application=${application}&locale=${locale}`
});

export const I18nService = {
  getLabels
};
