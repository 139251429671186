import { API_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';

// GET
const healthcheck = () => RequestHelper.GET({
  url: `${API_URL}healthcheck`,
  withAuth: false
});

const ping = () => fetch(`${API_URL}ping`).then(response => response);

export const HealthcheckService = {
  healthcheck,
  ping
};
