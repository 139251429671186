import { API_URL_DATAFLUIDES } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';

const fluidBalancesUrl = `${API_URL_DATAFLUIDES}fluid-balances`;

const isEditableFluidBalanceFromInstitutionModelId = ((institutionModelId, year) => {
  const entries = [];
  return RequestHelper.POST({
    url: `${fluidBalancesUrl}/editable`,
    body: { institutionModelId, year, entries }
  });
});

const getInstitutionIdsWithEditableFluidBalancesForYear = ((year) => RequestHelper.GET({
    url: `${fluidBalancesUrl}/filter-editable-institution?year=${year}`
  })
);

const synchronizeFromDatabilan = (databilanFluidBalanceView) => RequestHelper.PUT({
    url: `${fluidBalancesUrl}/synchronize-from-databilan`,
    body: databilanFluidBalanceView
  });

export const DatafluidesFluidBalanceService = {
  isEditableFluidBalanceFromInstitutionModelId,
  getInstitutionIdsWithEditableFluidBalancesForYear,
  synchronizeFromDatabilan
};
