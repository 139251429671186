import { FluidBalanceView } from 'models/detail/FluidBalanceView';
import { API_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';
import { SelectItem } from 'utils/types';

const fluidBalancesUrl = `${API_URL}fluid-balances`;

// GET
const getYears: () => Promise<SelectItem[]> = () => RequestHelper.GET({ url: `${fluidBalancesUrl}/years` });

// POST
const getAllBalances: (
  filter
) => Promise<FluidBalanceView[]> = filter => RequestHelper.POST({ url: fluidBalancesUrl, body: filter });

const getFluidBalanceFromYear: (year : string, institutionId: string) => Promise<Array<FluidBalanceView>> = ((year, institutionId) => RequestHelper.POST({ url: `${fluidBalancesUrl}/for-operator`, body: { year, institutionIds: [institutionId] } }));

const getAllFluidBalanceFromYearAndCompany = ((year, companyId) => (
  RequestHelper.POST({ url: `${fluidBalancesUrl}/for-operator/${companyId}`, body: { year, institutionIds: [] } })
));

const exportReport = ({ date, fluid = null }) => RequestHelper.POST({ url: `${fluidBalancesUrl}/export`, body: { date, fluid } });

const notifExportReport = ({ date, fluid = null }, signal) => RequestHelper.POST({
  url: `${fluidBalancesUrl}/notif-export`,
  body: { date, fluid },
  signal
});

const massSynchronizeWithDatafluides = ((year) => (
  RequestHelper.POST({
    url: `${fluidBalancesUrl}/mass-synchronize-with-datafluides?year=${year}`
  })
));

export const FluidBalancesService = {
  getYears,
  getAllBalances,
  getFluidBalanceFromYear,
  getAllFluidBalanceFromYearAndCompany,
  exportReport,
  notifExportReport,
  massSynchronizeWithDatafluides
};
