import { API_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';

const companyUrl = `${API_URL}companies`;
const identifierUrl = `${API_URL}identifiers`;

// GET DATABILAN
const getCompanyStructureLevels = companyId => RequestHelper.GET({ url: `${companyUrl}/${companyId}/structureLevels` });
const getCompanyTree = (companyUuid, activity) => RequestHelper.GET({ url: `${companyUrl}/${companyUuid}/tree?activity=${activity}` });

const getParentCompany = companyId => RequestHelper.GET({ url: `${companyUrl}/${companyId}/parent` });

const isPublic = companyId => RequestHelper.GET({ url: `${companyUrl}/${companyId}/public` });

// GET INSEE
const getCompanyBySiren = (siren = '') => RequestHelper.GET({ url: `${identifierUrl}/siren/${siren}` });

const getInstitutionBySiret = (siret = '') => RequestHelper.GET({ url: `${identifierUrl}/siret/${siret}` });

export const CompanyService = {
  getCompanyBySiren,
  getCompanyStructureLevels,
  getInstitutionBySiret,
  getCompanyTree,
  getParentCompany,
  isPublic
};
