import { API_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';

const trackDechetsUrl = `${API_URL}track-dechets`;

// GET
const getConnectionStatus = () => RequestHelper.GET({ url: `${trackDechetsUrl}` });
const getTrackDechetsInfos = () => RequestHelper.GET({ url: `${trackDechetsUrl}/check` });

// POST
const setTrackDechetsKey = apiKey => RequestHelper.POST({ url: `${trackDechetsUrl}`, body: { apiKey } });

export const TrackDechetsService = {
  getConnectionStatus,
  getTrackDechetsInfos,
  setTrackDechetsKey
};
