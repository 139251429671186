import { EquipmentSummary, EquipmentSummaryItem } from 'models';
import { RefrigerationUnitView } from 'models/detail/RefrigerationUnitView';
import { EquipmentFilter } from 'models/request/list/EquipmentFilter';
import { API_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';
import { SelectItem } from 'utils/types';
import { RefrigerationUnitFilter } from '../models/request/list/RefrigerationUnitFilter';

const equipmentsUrl = `${API_URL}equipments`;

// GET
const getEquipmentDetail: (
  id: string
) => Promise<RefrigerationUnitView> = (
  id
) => RequestHelper.GET({ url: `${equipmentsUrl}/${id}` });

const getInstallationTypes: () => Promise<SelectItem[]> = () => RequestHelper.GET({ url: `${equipmentsUrl}/installation-types/options` });

// POST
const getEquipmentList: (
  params: EquipmentFilter
) => Promise<EquipmentSummaryItem[]> = async (
  params
) => {
  // Since the equipment options can be quite long to load, the request is done by blocks
  let isLast = false;
  let page = 1;
  const size = 500;
  const fullList: EquipmentSummaryItem[] = [];
  while (!isLast) {
    /* eslint-disable */
    await RequestHelper.POST({url:`${equipmentsUrl}/extended-options?page=${page}&size=${size}`, body:params})
      .then((response: EquipmentSummary) => {
        fullList.push(...response.content);
        isLast = response.last;
      });
    page++;
  }
  return Promise.resolve(fullList);
};

const importEquipments = document => RequestHelper.POST({url:`${equipmentsUrl}/import`, body:document});

const importEquipmentOwners = document => RequestHelper.POST({url:`${equipmentsUrl}/import-owners`, body:document});

const createEquipment: (
  equipmentView: RefrigerationUnitView
) => Promise<void> = (
  equipmentView
) => RequestHelper.POST({url:`${equipmentsUrl}`, body:equipmentView});

const updateEquipment: (
  equipmentId: string,
  equipmentView: RefrigerationUnitView
) => Promise<void> = (
  equipmentId,
  equipmentView
) => RequestHelper.PUT({url:`${equipmentsUrl}/${equipmentId}`, body:equipmentView});

const mergeEquipment = (hashIdEdited, hashIdMerged) => RequestHelper.POST({url:`${equipmentsUrl}/${hashIdEdited}/fuse`, body:{ otherId: hashIdMerged }});

const exportEquipments = (filter: RefrigerationUnitFilter) => RequestHelper.POST({url:`${equipmentsUrl}/export`, body:filter});

const exportAsyncEquipments = (filter: RefrigerationUnitFilter, signal: AbortSignal) => RequestHelper.POST({
  url:`${equipmentsUrl}/notif-export`,
  body:filter,
  signal
});

const toggleActivation = (equipmentId: string,) => RequestHelper.PUT({url:`${equipmentsUrl}/${equipmentId}/toggle-activation`});

export const EquipmentsService = {
  getEquipmentList,
  getEquipmentDetail,
  getInstallationTypes,
  createEquipment,
  importEquipments,
  importEquipmentOwners,
  updateEquipment,
  mergeEquipment,
  exportEquipments,
  exportAsyncEquipments,
  toggleActivation
};