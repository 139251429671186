// Enum
import { HeadersType } from 'models/headers.model';
import { MethodEnum } from 'models/http.enum';

import { userStore } from 'stores';

export const getDefaultHeaders = (method = 'GET'): HeadersType => {
  const headers: HeadersType = {
    'Content-Type': 'application/json'
  };

  switch (method.toLowerCase()) {
    case 'post':
    case 'put':
      headers['Content-Type'] = 'application/json';
      break;
    default:
      break;
  }

  return headers;
};

interface InitModel {
  body?: string | object | FormData;
  headers?: HeadersType;
  method: MethodEnum;
  signal?: AbortSignal;
}

export const httpSettings = (
  settings: InitModel = { method: MethodEnum.GET },
  internalAPI = true,
  withAuth = true
): object => {
  const headers: HeadersType = settings.headers || getDefaultHeaders(settings.method);
  const init: InitModel = settings;

  if (init.body) {
    switch (headers['Content-Type']) {
      case 'application/json':
        if (!(init.body instanceof FormData)) {
          init.body = JSON.stringify(init.body);
        }
        break;
      case 'application/x-www-form-urlencoded': {
        const formDataEntries: any = init.body instanceof FormData ? init.body.entries() : Object.entries(init.body);
        const formDataArray: string[] = [];
        formDataEntries.forEach(entry => {
          formDataArray.push(`${encodeURIComponent(entry.key)}=${encodeURIComponent(entry.value)}`);
        });
        init.body = formDataArray.join('&');
        break;
      } default:
        break;
    }
  }

  const { keycloak } = userStore;
  // Refresh the token for each authenticated call to the backend
  if (withAuth && keycloak) {
    keycloak.updateToken(-1);
  }

  const token = keycloak?.token || '';
  const locale = keycloak?.tokenParsed?.locale || '';

  headers.Accept = 'application/json';
  if (token && !headers.Authorization && internalAPI) {
    headers.Authorization = `Bearer ${token}`;
  }
  if (locale && internalAPI) {
    headers['Accept-Language'] = locale;
  }

  init.headers = headers;
  return init;
};
