import { API_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';

const applicationRolesUrl = `${API_URL}application-roles`;

// GET

// POST
const getOptions: () => Promise<any> = () => RequestHelper.GET({ url: `${applicationRolesUrl}/options` });

export const ApplicationRolesService = {
  getOptions
};
