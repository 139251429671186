import { API_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';

const institutionGroupsURL = `${API_URL}institution-groups`;

const getInstitutionGroup = (institutionGroupUuid) => RequestHelper.GET({ url: `${institutionGroupsURL}/${institutionGroupUuid}` });
const deleteInstitutionGroup = (institutionGroupUuid) => RequestHelper.DELETE({ url: `${institutionGroupsURL}/${institutionGroupUuid}` });
const createInstitutionGroup = (institutionGroup, activity) => RequestHelper.POST({ url: `${institutionGroupsURL}?activity=${activity}`, body: institutionGroup });
const updateInstitutionGroup = (institutionGroupUuid, institutionGroup, activity) => RequestHelper.PUT({ url: `${institutionGroupsURL}/${institutionGroupUuid}?activity=${activity}`, body: institutionGroup });

export const InstitutionGroupService = {
  getInstitutionGroup,
  createInstitutionGroup,
  deleteInstitutionGroup,
  updateInstitutionGroup
};
