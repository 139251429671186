import {
  action, makeObservable, observable, runInAction
} from 'mobx';
import { UserView } from 'models';
import { ScopeView } from 'models/detail/ScopeView';
import { UsersService } from 'services';
import { KeyCloakUtils } from 'utils/keycloak/keycloakUtils';
import { StructureLevelOption } from 'utils/types/StructureLevelOption';

class UserStore {
  isLoadingCurrentData: boolean;
  isIdentified: boolean;
  isOffline: boolean;
  refreshInterventions: boolean;

  keycloak?: Keycloak.KeycloakInstance;
  deviceId: string;

  currentUser?: UserView;
  currentLevel?: StructureLevelOption;
  treeRootCompany?: StructureLevelOption;
  currentCompany?: StructureLevelOption;
  scope: ScopeView;
  accessibleLevels: StructureLevelOption[];

  constructor() {
    this.isLoadingCurrentData = false;
    this.isIdentified = false;
    this.isOffline = false;
    this.refreshInterventions = false;
    this.deviceId = '';
    this.accessibleLevels = [];

    makeObservable(this, {
      isLoadingCurrentData: observable,
      isIdentified: observable,
      isOffline: observable,
      refreshInterventions: observable,
      keycloak: observable,
      deviceId: observable,
      currentUser: observable,
      currentLevel: observable,
      currentCompany: observable,
      accessibleLevels: observable,
      scope: observable,
      connectUser: action,
      useOfflineUser: action,
      disconnectUser: action,
      loadAvailableLevels: action,
      setOfflineMode: action,
      setDeviceId: action,
      setRefreshInterventions: action,
      getUserName: action,
      getUserInitials: action,
      getLevelName: action,
      isViewingInstitution: action
    });
  }

  connectUser = async () => {
    const currentKeycloakInstance = await KeyCloakUtils.init();
    this.keycloak = currentKeycloakInstance;
    this.isLoadingCurrentData = true;

    if (currentKeycloakInstance) {
      UsersService.getCurrentScope().then(resp => runInAction(() => {
        this.scope = resp;
        this.currentLevel = resp.currentStructureLevel;
        this.currentCompany = resp.company;
        this.accessibleLevels = resp.accessibleLevels;
        this.treeRootCompany = resp.currentStructureLevelIsParentCompany ? this.accessibleLevels[1] : this.currentCompany;
        // eslint-disable-next-line no-console
      })).catch(err => console.error(err));
      await UsersService.getCurrentUser().then(resp => runInAction(() => {
        this.isIdentified = true;
        this.currentUser = resp;
        this.isLoadingCurrentData = false;
      }));

      return this.currentUser;
    }
    this.disconnectUser();
    return undefined;
  };

  refreshScope() {
    UsersService.getCurrentScope().then(resp => runInAction(() => {
      this.scope = resp;
      this.currentLevel = resp.currentStructureLevel;
      this.currentCompany = resp.company;
      this.accessibleLevels = resp.accessibleLevels;
      this.treeRootCompany = resp.currentStructureLevelIsParentCompany ? this.accessibleLevels[1] : this.currentCompany;
    }));
  }

  useOfflineUser(user: UserView) {
    this.isIdentified = true;
    this.currentUser = user;
    this.currentLevel = user.currentLevel;
  }

  disconnectUser() {
    this.isIdentified = false;
    this.currentUser = undefined;
    this.currentLevel = undefined;
    this.keycloak.clearToken();
    this.isLoadingCurrentData = false;
    return this.keycloak.logout();
  }

  loadAvailableLevels() {
    UsersService.getLevelOptions().then(resp => runInAction(() => {
      this.accessibleLevels = resp;
    }));
  }

  setOfflineMode(isOffline: boolean) {
    this.isOffline = isOffline;
  }

  setDeviceId(deviceId: string) {
    this.deviceId = deviceId;
  }

  setRefreshInterventions(forceRefresh: boolean) {
    this.refreshInterventions = forceRefresh;
  }

  getUserName() {
    if (!this.currentUser || !this.currentUser.person) {
      return '';
    }
    return `${this.currentUser.person.firstName} ${this.currentUser.person.lastName}`;
  }

  getUserInitials() {
    if (!this.currentUser || !this.currentUser.person) {
      return '';
    }
    return `${this.currentUser.person.firstName.charAt(0)}${this.currentUser.person.lastName.charAt(0)}`;
  }

  getLevelName() {
    if (!this.currentLevel) {
      return '';
    }
    return this.currentLevel.label;
  }

  isViewingInstitution() {
    if (!this.currentLevel) {
      return false;
    }
    return this.currentLevel.type === 'INSTITUTION_MODEL';
  }
}

export const userStore = new UserStore();
