import { Slide, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as Sentry from '@sentry/browser';
import {
  FlexContainer, Footer, FooterMenu, Header, SkeletonMain
} from 'components';
import GoogleAnalytics from 'components/GoogleAnalytics/GoogleAnalytics';
import { differenceInMinutes } from 'date-fns';
import { useModal, useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { UserView } from 'models';
import { FormFrontView } from 'models/detail/FormFrontView';
import { InterventionFrontView } from 'models/detail/InterventionFrontView';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Routes } from 'routes/routes';
import {
  CountriesService,
  EquipmentOwnersService,
  EquipmentsService,
  FluidsService,
  HealthcheckService,
  InterventionsService,
  ToolsService,
  UsersService
} from 'services';
import styled from 'styled-components';
import {
  APPLICATION_ROLES,
  DISTRIBUTOR_ROLES,
  INTERVENTION_ACCESS_ROLES,
  INTERVENTION_STATUS
} from 'utils/constants';
import {
  DocumentHelper,
  FormHelper,
  frenchLocale,
  generateUUID,
  IndexedDBHelper,
  InterventionHelper,
  StorageHelper,
  translate,
  UserHelper
} from 'utils/helpers';
import { KEYCLOAK_STORE, REFERENCE_DATA_STORE } from 'utils/helpers/IndexedDBHelper';
import {
  BOTTLES_KEY, COUNTRIES_KEY,
  EQUIPMENT_OWNERS_KEY,
  EQUIPMENTS_KEY,
  FLUIDS_KEY,
  INSTALLATION_TYPES_KEY,
  INTERVENTION_STATUSES_KEY,
  LEAK_DETECTORS_KEY,
  SCALES_KEY
} from 'utils/helpers/InterventionHelper';

const MINUTES_BEFORE_DOWNLOADING_AGAIN = 5;

const useStyles = makeStyles(() => ({
  snackbar: {
    bottom: 80
  }
}));

const SlideTransition = props => <Slide {...props} direction="up" />;

const Container = styled(FlexContainer).attrs({
  flexDirection: 'column',
  justifyContent: 'center'
})`
  min-height: 100vh;

  .MuiSnackbarContent-root {
    background: rgba(0, 0, 0, 0.8);
  }
`;

const Main = styled.main`
  flex: 1;
  padding-bottom: 120px;
  background-color: var(--white);
`;

const CustomSnackBar = ({
  open, message, className
}) => (
  <Snackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    autoHideDuration={10000}
    className={className}
    message={message}
    open={open}
    TransitionComponent={SlideTransition}
  />
);

const DefaultApp = observer(() => {
  const {
    countryStore, fluidStore, i18nStore, userStore
  } = useStores();
  const { enqueueSnackbar } = useSnackbar();
  const { open } = useModal();
  const classes = useStyles();

  const [isDBopen, setIsDBopen] = useState(false);
  const [isIntegrityCompromised, setIsIntegrityCompromised] = useState(false);
  const [isIndexedDBNotSupported, setIsIndexedDBNotSupported] = useState(false);

  const [isContextLoaded, setContextLoaded] = useState(false);
  const [isUserConnected, setUserConnected] = useState(false);
  const [isTranslationLoaded, setTranslationLoaded] = useState(false);

  const [isDownloadingInterventions, setIsDownloadingInterventions] = useState(false);

  const checkHealth = useCallback(() => (
    HealthcheckService.healthcheck()
      .then((healthcheck) => {
        userStore.setOfflineMode(false);
        setIsIntegrityCompromised(false);
        const serviceNotOk = Object.values(healthcheck).find(status => status !== 'OK');
        serviceNotOk && setIsIntegrityCompromised(true);
      })
      .catch(() => userStore.setOfflineMode(true))
    // eslint-disable-next-line
  ), []);

  const getDeviceAutomaticDownload = useCallback(async () => {
    const { currentUser } = userStore;
    if (!currentUser) {
      return;
    }
    if (UserHelper.hasAccessRight(DISTRIBUTOR_ROLES)) {
      return;
    }
    const storedDeviceId = await IndexedDBHelper.getData({
      store: KEYCLOAK_STORE,
      key: `deviceId_${currentUser.accountId}`
    }).then(sdi => sdi);

    const storedAutomaticDownload = await IndexedDBHelper.getData({
      store: KEYCLOAK_STORE,
      key: `automaticInterventionDownload_${currentUser.accountId}`
    }).then(aid => aid);

    // If the user has activated the automatic download OR it's not defined for the first time
    if (storedAutomaticDownload || storedAutomaticDownload === undefined) {
      // Return early when testing with Cypress to not display the popup
      if (storedAutomaticDownload && storedAutomaticDownload.isTesting) return;

      UsersService.getUserConfigsDeviceId().then((resp) => {
        if (!resp) {
          open({
            type: 'SELECT_PRIMARY_DEVICE',
            onConfirm: (isPrimaryDevice) => {
              if (isPrimaryDevice) {
                const newDeviceId = generateUUID();
                UsersService.setUserConfigsDeviceId(newDeviceId).then(() => {
                  IndexedDBHelper.updateData({
                    store: KEYCLOAK_STORE,
                    key: `automaticInterventionDownload_${currentUser.accountId}`,
                    data: true
                  });
                  IndexedDBHelper.updateData({
                    store: KEYCLOAK_STORE,
                    key: `deviceId_${currentUser.accountId}`,
                    data: newDeviceId
                  }).then(() => userStore.setDeviceId(newDeviceId));
                }).catch(error => enqueueSnackbar(error?.message || error, { variant: 'error' }));
              } else {
                IndexedDBHelper.updateData({
                  store: KEYCLOAK_STORE,
                  key: `automaticInterventionDownload_${currentUser.accountId}`,
                  data: false
                });
                IndexedDBHelper.deleteData({
                  store: KEYCLOAK_STORE,
                  key: `deviceId_${currentUser.accountId}`
                });
              }
            }
          });
          return;
        }

        if (resp === storedDeviceId) {
          userStore.setDeviceId(storedDeviceId);
          return;
        }

        IndexedDBHelper.updateData({
          store: KEYCLOAK_STORE,
          key: `automaticInterventionDownload_${currentUser.accountId}`,
          data: false
        });

        IndexedDBHelper.deleteData({
          store: KEYCLOAK_STORE,
          key: `lastAutomaticInterventionDownload_${currentUser.accountId}`
        });

        IndexedDBHelper.deleteData({
          store: KEYCLOAK_STORE,
          key: `deviceId_${currentUser.accountId}`
        });
      }).catch(error => !userStore.isOffline && enqueueSnackbar(error?.message || error, { variant: 'error' }));
    }
    // eslint-disable-next-line
  }, [userStore.isOffline]);

  const fetchAndSaveGeneralLists = useCallback(async () => {
    FluidsService.getFluidOptionsExtended().then((resp) => {
      if (resp) {
        const data = resp.map(fluid => ({
          ...fluid,
          key: fluid.id,
          value: fluid.id,
          label: fluid.name
        }));
        IndexedDBHelper.updateData({
          store: REFERENCE_DATA_STORE,
          key: FLUIDS_KEY,
          data
        });
      }
    });

    EquipmentsService.getInstallationTypes()
      .then((resp) => {
        if (resp) {
          IndexedDBHelper.updateData({
            store: REFERENCE_DATA_STORE,
            key: INSTALLATION_TYPES_KEY,
            data: resp
          });
        }
      });

    if (UserHelper.hasAccessRight(INTERVENTION_ACCESS_ROLES)) {
      await InterventionsService.getInterventionStatuses().then((resp) => {
        if (resp) {
          IndexedDBHelper.updateData({
            store: REFERENCE_DATA_STORE,
            key: INTERVENTION_STATUSES_KEY,
            data: resp.reduce((IS, status) => ({
              ...IS,
              [status.key]: {
                ...status,
                icon: Object.values(INTERVENTION_STATUS).find(stat => stat.key === status.key)?.icon,
                color: Object.values(INTERVENTION_STATUS).find(stat => stat.key === status.key)?.color
              }
            }), {})
          });
        }
      });
    }
  }, []);

  const fetchAndSaveListForOfflineUse = useCallback(async () => {
    // Fetch the datas needed for the form
    EquipmentOwnersService.getEquipmentOwnerListFull({ size: 0 })
      .then((resp) => {
        if (resp) {
          IndexedDBHelper.updateData({
            store: REFERENCE_DATA_STORE,
            key: EQUIPMENT_OWNERS_KEY,
            data: resp
          });
        }
      });

    EquipmentsService.getEquipmentList({})
      .then((resp) => {
        if (resp) {
          const mappedEquipments = DocumentHelper.getDatasWithDocumentsFormatted({ datas: resp });
          IndexedDBHelper.updateData({
            store: REFERENCE_DATA_STORE,
            key: EQUIPMENTS_KEY,
            data: mappedEquipments
          });
        }
      });

    CountriesService.getCountryDetails()
      .then((resp) => {
        if (resp) {
          IndexedDBHelper.updateData({
            store: REFERENCE_DATA_STORE,
            key: COUNTRIES_KEY,
            data: resp
          });
        }
      });

    if (UserHelper.hasAccessRight(INTERVENTION_ACCESS_ROLES)) {
      ToolsService.getLeakDetectors()
        .then((resp) => {
          if (resp) {
            const mappedLeakDetectors = DocumentHelper.getDatasWithDocumentsFormatted({ datas: resp?.content ?? [] });
            IndexedDBHelper.updateData({
              store: REFERENCE_DATA_STORE,
              key: LEAK_DETECTORS_KEY,
              data: mappedLeakDetectors
            });
          }
        });
      ToolsService.getScales()
        .then((resp) => {
          if (resp) {
            const mappedScales = DocumentHelper.getDatasWithDocumentsFormatted({ datas: resp?.content ?? [] });
            IndexedDBHelper.updateData({
              store: REFERENCE_DATA_STORE,
              key: SCALES_KEY,
              data: mappedScales
            });
          }
        });
    }

    await FluidsService.getBottleOptionsExtended({}).then((resp) => {
      if (resp) {
        IndexedDBHelper.updateData({
          store: REFERENCE_DATA_STORE,
          key: BOTTLES_KEY,
          data: resp.map(bottle => ({
            ...bottle,
            key: bottle.id,
            value: bottle.id,
            label: bottle.identifier
          }))
        });
      }
    });
  }, []);

  const downloadInterventionsPending = useCallback((mobileId) => {
    if (mobileId && !userStore.isOffline) {
      setIsDownloadingInterventions(true);

      return InterventionsService.getInterventionsPending(mobileId).then((interventions) => {
        if (interventions.length === 0) {
          return null;
        }

        const saveAllInterventions = Promise.all(interventions.map((intervention) => {
          const { hashId } = intervention;
          return InterventionHelper.saveInterventionDetailAndForm(hashId, intervention);
        }));

        return saveAllInterventions.then(() => {
          userStore.setRefreshInterventions(true);
          enqueueSnackbar(translate('common.interventionDowloaded', { nbInterventions: interventions.length }), { variant: 'success' });
        });
      })
        .catch(error => enqueueSnackbar(error?.message || error, { variant: 'error' }))
        .finally(() => {
          setIsDownloadingInterventions(false);
          IndexedDBHelper.updateData({
            store: KEYCLOAK_STORE,
            key: `lastAutomaticInterventionDownload_${userStore.currentUser?.accountId}`,
            data: new Date()
          });
        });
    }

    return null;
    // eslint-disable-next-line
  }, [userStore.currentUser, userStore.isOffline, enqueueSnackbar]);

  const downloadInterventionsAndLists = useCallback((mobileId) => {
    if (UserHelper.hasAccessRight(INTERVENTION_ACCESS_ROLES)) {
      fetchAndSaveListForOfflineUse();
      downloadInterventionsPending(mobileId);
    }
  }, [fetchAndSaveListForOfflineUse, downloadInterventionsPending]);

  const checkCanEditDownloadedInterventions = useCallback(() => {
    InterventionHelper.getStoredInterventionList()
      .then(async (storedIntList) => {
        if (storedIntList && storedIntList.length > 0) {
          await Promise.all(storedIntList.map((storedInt) => {
            if (!storedInt.hashId) {
              return null;
            }
            return InterventionsService.getInterventionStatus(storedInt.hashId)
              .then(async (status) => {
                const interventionOutOfSync: InterventionFrontView = {
                  ...storedInt,
                  isOutOfSync: !status?.canEdit,
                  outOfSyncStatus: status,
                  operator: {
                    id: '',
                    fullName: status.assignedOperator?.label,
                    personModelId: status.assignedOperator?.value
                  }
                };
                await InterventionHelper.saveInterventionDetailStored(storedInt.hashId, interventionOutOfSync);
                return interventionOutOfSync;
              });
          }));
        }
      });
  }, []);

  // Check the state of the app and init the context
  useEffect(() => {
    // Set an interval to call the healthcheck service
    if (navigator.onLine) {
      checkHealth();
      setInterval(() => {
        checkHealth();
      }, 1000 * 60);
    }

    // Remove manifest on Desktop to not have the PWA experience
    window.addEventListener('load', () => {
      if (navigator.userAgent.indexOf('Mobile') === -1) {
        const manifest = document.querySelector('link[rel="manifest"]');
        if (manifest) {
          manifest.remove();
        }
      }
    });

    window.addEventListener('online', () => userStore.setOfflineMode(!navigator.onLine));
    window.addEventListener('offline', () => userStore.setOfflineMode(!navigator.onLine));

    userStore.setOfflineMode(!navigator.onLine);

    // Check indexedDB support
    if (!window.indexedDB) {
      setIsIndexedDBNotSupported(true);
    } else {
      // Persist storage on load
      StorageHelper.checkStoragePersisted();
      // Open main database
      IndexedDBHelper.openDatabase().then(() => setIsDBopen(true));
    }
    setContextLoaded(true);
    // eslint-disable-next-line
  }, [checkHealth]);

  // Connect the user or fetch the previous connected user if offline
  useEffect(() => {
    // Wait until the context is loaded
    if (!isContextLoaded) {
      return;
    }
    // Only connect the user once
    if (isUserConnected) {
      return;
    }
    if (!userStore.isOffline) {
      // Connect the user and save the data in the store
      userStore.connectUser().then((currentUser) => {
        if (currentUser) {
          // Store the connected user in the IndexedDB for offline usage
          IndexedDBHelper.updateData({
            store: KEYCLOAK_STORE,
            key: 'currentUser',
            data: JSON.parse(JSON.stringify(currentUser))
          });
          Sentry.setUser({
            id: currentUser.accountId,
            username: currentUser.login
          });
          setUserConnected(true);
        }
        InterventionHelper.setCurrentUserAndInstitution(currentUser);
      });
      return;
    }

    // When offline, use the stored data from the previously connected user
    IndexedDBHelper.getData({
      store: KEYCLOAK_STORE,
      key: 'currentUser'
    }).then((user: UserView) => {
      userStore.useOfflineUser(user);
      InterventionHelper.setCurrentUserAndInstitution(user);
    });
    // eslint-disable-next-line
  }, [isUserConnected, isContextLoaded, userStore.isOffline]);

  // Set the language and fetch the translations
  useEffect(() => {
    // Wait until the context is loaded
    if (!isUserConnected) {
      return;
    }
    if (isTranslationLoaded) {
      return;
    }
    i18nStore.loadLanguageList();
    // Use the locale in the token to initialize the language and translations
    if (userStore.keycloak && userStore.keycloak.tokenParsed) {
      i18nStore.checkLanguage(userStore.keycloak.tokenParsed.locale || frenchLocale)
        .then(requiresUpdate => !requiresUpdate && i18nStore.loadLanguage(userStore.keycloak.tokenParsed.locale || frenchLocale)
          .then(() => setTranslationLoaded(true)));
      return;
    }

    // Fallback to the currently stored language
    const currentLanguage = localStorage.getItem('i18nextLng');
    if (currentLanguage) {
      i18nStore.checkLanguage(currentLanguage)
        .then(requiresUpdate => !requiresUpdate && i18nStore.loadLanguage(currentLanguage)
          .then(() => setTranslationLoaded(true)));
      return;
    }

    // Fallback to the French locale
    i18nStore.loadLanguage(frenchLocale)
      .then(() => setTranslationLoaded(true));
    // eslint-disable-next-line
  }, [isUserConnected]);

  // Fetch the available levels and set the current level
  useEffect(() => {
    if (!isUserConnected) {
      return;
    }
    if (userStore.isIdentified && !userStore.isOffline) {
      userStore.loadAvailableLevels();
    }
    // eslint-disable-next-line
  }, [isUserConnected, userStore.isIdentified, userStore.isOffline]);

  // Fetch the data lists for offline use
  useEffect(() => {
    if (!isUserConnected) {
      return;
    }
    // If the user doesn't have access to the server, do nothing
    if (userStore.isOffline || !userStore.isIdentified) {
      return;
    }
    // Load the basic data no matter the situation
    fetchAndSaveGeneralLists();
    countryStore.loadCountryList();
    fluidStore.loadFluidBottleOptions();
    fluidStore.loadFluidOptions();
    fluidStore.loadFluidExtendedOptions();
    // Make some calls when the user is logged in and institution is loaded
    if (userStore.isViewingInstitution()) {
      fluidStore.loadFluidBottleExtendedOptions();

      // Only operators need to download interventions and lists
      if (isDBopen && UserHelper.hasAccessRight([APPLICATION_ROLES.OPERATOR])) {
        // Load all elements for the first time
        getDeviceAutomaticDownload().then(() => {
          downloadInterventionsAndLists(userStore.deviceId);
        }).finally(() => checkCanEditDownloadedInterventions());

        // Resync the interventions every 15 minutes
        setInterval(() => {
          getDeviceAutomaticDownload().then(() => {
            downloadInterventionsPending(userStore.deviceId);
          }).finally(() => checkCanEditDownloadedInterventions());
        }, 1000 * 60 * 15);

        // Resync the data lists every hour
        setInterval(() => {
          getDeviceAutomaticDownload().then(() => {
            fetchAndSaveListForOfflineUse();
          }).finally(() => checkCanEditDownloadedInterventions());
        }, 1000 * 60 * 60);
      } else if (UserHelper.hasAccessRight([APPLICATION_ROLES.APPOINTMENT_MANAGER, APPLICATION_ROLES.ADMIN])) {
        fetchAndSaveListForOfflineUse();
      }
    }
    // eslint-disable-next-line
  }, [isUserConnected, userStore.isOffline, userStore.isIdentified, isDBopen, userStore.deviceId, checkCanEditDownloadedInterventions,
    downloadInterventionsAndLists, downloadInterventionsPending, fetchAndSaveGeneralLists, fetchAndSaveListForOfflineUse,
    getDeviceAutomaticDownload, userStore.currentLevel]);

  // Fetch pending interventions
  useEffect(() => {
    if (!isUserConnected) {
      return;
    }
    if (userStore.deviceId) {
      downloadInterventionsPending(userStore.deviceId);
    }
  }, [isUserConnected, userStore.deviceId, downloadInterventionsPending]);

  // Migrate old interventions if needed
  useEffect(() => {
    if (!isUserConnected || !isDBopen) {
      return;
    }
    // Retrieve OLD interventions in the localStorage and put them in the IndexedDB
    const interventionsInLS = InterventionHelper.getStoredInterventionListFromLocalStorage();
    if (interventionsInLS.length > 0) {
      InterventionHelper.transferCurrentInterventionsToIndexedDB(interventionsInLS)
        .catch(error => enqueueSnackbar(error?.message || error, { variant: 'error' }));
    }
  }, [isDBopen, isUserConnected, enqueueSnackbar]);

  // Synchronize stored interventions awaiting transfer
  useEffect(() => {
    if (!isUserConnected || !isDBopen || userStore.isOffline) {
      return;
    }
    // Check if it has been more than 5 minutes that the interventions were downloaded
    IndexedDBHelper.getData({
      store: KEYCLOAK_STORE,
      key: `lastAutomaticInterventionDownload_${userStore.currentUser?.accountId}`
    }).then((lastDate) => {
      // Seems weird but it's to avoid double download of the list
      if (lastDate && differenceInMinutes(new Date(), new Date(lastDate)) > MINUTES_BEFORE_DOWNLOADING_AGAIN) {
        getDeviceAutomaticDownload();
      } else {
        getDeviceAutomaticDownload();
      }
    });

    // Try to submit waiting interventions
    const submitWaitingToSubmitInterventions = async () => {
      const waitingToSubmitInterventions = await InterventionHelper.getStoredInterventionList()
        .then(intList => intList.filter(int => int.isWaitingToSubmit));

      await Promise.all(waitingToSubmitInterventions.map(async (intervention) => {
        const interventionHashId = intervention.hashId || intervention.fileNumber;
        const interventionForm: FormFrontView = await InterventionHelper.loadInterventionFormStored(interventionHashId);
        return InterventionsService.finishIntervention(interventionHashId, {
          ...intervention,
          form: FormHelper.BUILD(interventionForm)
        }).then(() => {
          enqueueSnackbar(translate('confirms.intervention.finishedOfflineSubmit', { fileNumber: intervention.fileNumber }), { variant: 'success' });
          return InterventionHelper.deleteInterventionStored(interventionHashId);
        }).catch((error) => {
          enqueueSnackbar(error, { variant: 'error' });
          // In case of error, remove the tag isWaitingToSubmit and save the detail again
          delete intervention.isWaitingToSubmit;
          return InterventionHelper.saveInterventionDetailStored(interventionHashId, intervention);
        });
      }));
      userStore.setRefreshInterventions(true);
    };

    // Timeout needed to avoid concurrencial access to IndexedDB
    // without it the deleteInterventionStored is not fired
    setTimeout(() => {
      submitWaitingToSubmitInterventions();
    }, 2000);
    // eslint-disable-next-line
  }, [userStore.isOffline, userStore.currentUser, isDBopen, isUserConnected, getDeviceAutomaticDownload, enqueueSnackbar]);

  if (!userStore.isIdentified || !isTranslationLoaded || !isDBopen || i18nStore.isLoading) {
    return <SkeletonMain />;
  }

  return (
    <Container>
      <Header
        deviceId={userStore.deviceId}
        downloadInterventionsAndLists={downloadInterventionsAndLists}
        isDBopen={isDBopen}
        isDownloadingInterventions={isDownloadingInterventions}
      />
      <CustomSnackBar className={classes.snackbar} message={translate('common.userIsOffline')} open={userStore.isOffline} />
      <CustomSnackBar className={classes.snackbar} message={translate('common.servicesDown')} open={isIntegrityCompromised} />
      <CustomSnackBar className={classes.snackbar} message={translate('errors.indexedDBNotSupported')} open={isIndexedDBNotSupported} />
      <Main>
        <Routes />
      </Main>
      <Footer />
      <FooterMenu />
      <GoogleAnalytics />
    </Container>
  );
});

export const App = withTranslation()(DefaultApp);
