import { API_URL } from 'utils/constants';
import { DocumentHelper, RequestHelper } from 'utils/helpers';

const usersUrl = `${API_URL}documents`;

// GET
const getImportFile = (identifier) => {
  RequestHelper.GET({ url: `${usersUrl}/import-files/${identifier}` }).then((response) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = DocumentHelper.getExcelWithBase64(response);
    downloadLink.download = response.name;
    downloadLink.click();
  });
};

export const DocumentsService = {
  getImportFile
};
