import { API_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';

const activitiesURL = `${API_URL}activities`;

const getDatabilanActivities = () => RequestHelper.GET({ url: activitiesURL });

export const ActivityService = {
  getDatabilanActivities
};
