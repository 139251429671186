import { OperatorSummary } from 'models';
import { API_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';

const institutionsUrl = `${API_URL}operators`;

// GET

// POST
const getOptions: () => Promise<OperatorSummary[]> = () => RequestHelper.POST({ url: `${institutionsUrl}/extended-options` });

export const OperatorsService = {
  getOptions
};
