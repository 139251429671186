import {
  BottleSummary, BottleView, Document, FluidManipulationFieldView, FluidVariationSummary, FluidView
} from 'models';
import { BottleFilter } from 'models/request/list/BottleFilter';
import { API_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';
import { SelectItem } from 'utils/types';

const fluidsUrl = `${API_URL}fluids`;
const fluidsBottleUrl = `${API_URL}fluid-bottles`;
const fluidsManipulationsUrl = `${API_URL}fluid-manipulations`;

// GET
const getBottle: (
  bottleId: string
) => Promise<BottleView> = (
  bottleId
) => RequestHelper.GET({ url: `${fluidsBottleUrl}/${bottleId}` });

const getBsd: (
  bottleId: string
) => Promise<Document> = bottleId => RequestHelper.GET({ url: `${fluidsBottleUrl}/${bottleId}/bsd` });

const getFluidVariations: (
  bottleId: string
) => Promise<FluidVariationSummary[]> = bottleId => RequestHelper.GET({ url: `${fluidsBottleUrl}/${bottleId}/variations` });

// POST

// FLUIDS BOTTLE
const getBottleList: (
  filter: BottleFilter,
  page: number
) => Promise<BottleSummary> = (
  filter = {},
  page = 1
) => RequestHelper.POST({ url: `${fluidsBottleUrl}/filter?page=${page}`, body: filter });

const getBottleOptions: (
  filter: BottleFilter
) => Promise<SelectItem[]> = (
  filter = {}
) => RequestHelper.POST({ url: `${fluidsBottleUrl}/options`, body: filter });

const getBottleOptionsExtended: (
  filter: BottleFilter
) => Promise<BottleView[]> = (
  filter = {}
) => RequestHelper.POST({ url: `${fluidsBottleUrl}/extended-options`, body: filter });

const createBottle: (
  form: BottleView
) => Promise<void> = form => RequestHelper.POST({ url: fluidsBottleUrl, body: form });

const importBottles: (
  document: Document
) => Promise<void> = document => RequestHelper.POST({ url: `${fluidsBottleUrl}/import`, body: document });

const exportBottles: (
  filter: BottleFilter
) => Promise<Document> = (
  filter = {}
) => RequestHelper.POST({ url: `${fluidsBottleUrl}/export`, body: filter });

const exportBottlesByEmail: (
  filter: BottleFilter,
  signal
) => Promise<void> = (
  filter = {},
  signal = null
) => RequestHelper.POST({
  url: `${fluidsBottleUrl}/notif-export`,
  body: filter,
  signal
});

// FLUIDS OPTIONS
const getFluidOptions: () => Promise<SelectItem[]> = () => RequestHelper.GET({ url: `${fluidsUrl}/options` });

const getFluidOptionsExtended: () => Promise<FluidView[]> = () => RequestHelper.GET({ url: `${fluidsUrl}/extended-options` });

// FLUIDS MANIPULATIONS
const checkFluidManipulations: (
  fluidManipulationField: FluidManipulationFieldView
) => Promise<void> = (
  fluidManipulationField
) => RequestHelper.POST({ url: `${fluidsManipulationsUrl}/validate`, body: fluidManipulationField });

const importFluidManipulations: (
  document: Document
) => Promise<void> = document => RequestHelper.POST({ url: `${fluidsManipulationsUrl}/import`, body: document });

// PUT
const updateBottle: (
  bottleId: string,
  form: BottleView
) => Promise<void> = (
  bottleId,
  form
) => RequestHelper.PUT({ url: `${fluidsBottleUrl}/${bottleId}`, body: form });

// DELETE
const deleteBottle: (
  bottleId: string
) => Promise<void> = bottleId => RequestHelper.DELETE({ url: `${fluidsBottleUrl}/${bottleId}` });

export const FluidsService = {
  createBottle,
  exportBottles,
  exportBottlesByEmail,
  getBottle,
  getBsd,
  getFluidVariations,
  getBottleList,
  getBottleOptions,
  getBottleOptionsExtended,
  getFluidOptions,
  getFluidOptionsExtended,
  checkFluidManipulations,
  importFluidManipulations,
  importBottles,
  updateBottle,
  deleteBottle
};
