import { FluidStockView } from 'models';
import { FluidStockStatsView } from 'models/detail/FluidStockStatsView';
import { API_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';
import { SelectItem } from 'utils/types';

const stocksUrl = `${API_URL}stocks`;
const fluidUrl = `${API_URL}fluids`;

// GET
const getAllStocks: () => Promise<FluidStockView[]> = () => RequestHelper.GET({ url: stocksUrl });

const getDetailStock: (
  id: string,
  year: SelectItem
) => Promise<FluidStockStatsView> = (label, year) => RequestHelper.GET({ url: `${fluidUrl}/${label}/stocks?year=${year}` });

const getValidationList = () => RequestHelper.GET({ url: `${stocksUrl}/validation-list` });

// POST
const validateStock = bottleList => RequestHelper.POST({ url: `${stocksUrl}/validate`, body: bottleList });

const importStocks = document => RequestHelper.POST({ url: `${stocksUrl}/import`, body: document });

export const StocksService = {
  getAllStocks,
  getDetailStock,
  getValidationList,
  validateStock,
  importStocks
};
