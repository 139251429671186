import { EntitySummary, EntityView } from 'models';
import { LegalEntityFilter } from 'models/request/list/LegalEntityFilter';
import { API_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';
import { SelectItem } from 'utils/types';

const legalEntitiesUrl = `${API_URL}legal-entities`;

const getLegalEntity: (
  entityId: string,
  types: string[]
) => Promise<EntityView> = (entityId, types) => RequestHelper.GET({ url: `${legalEntitiesUrl}/${entityId}?types=${types}` });

const filterLegalEntities: (
  filter: LegalEntityFilter,
  page : number
) => Promise<EntitySummary> = (
  filter = {},
  page = 1
) => RequestHelper.POST({ url: `${legalEntitiesUrl}/filter?page=${page}`, body: filter });

const getLegalEntitiesOptionsFiltered: (
  filter: LegalEntityFilter,
) => Promise<SelectItem[]> = filter => RequestHelper.POST({ url: `${legalEntitiesUrl}/filter/options`, body: filter });

const createLegalEntity: (
  entity: EntityView
) => Promise<void> = supplier => RequestHelper.POST({ url: legalEntitiesUrl, body: supplier });

const updateLegalEntity: (
  entityId: string,
  entity: EntityView
) => Promise<void> = (entityId, entity) => RequestHelper.PUT({ url: `${legalEntitiesUrl}/${entityId}`, body: entity });

const importLegalEntities = form => RequestHelper.POST({ url: `${legalEntitiesUrl}/import-legal-entities`, body: form });

export const LegalEntityService = {
  getLegalEntity,
  filterLegalEntities,
  createLegalEntity,
  updateLegalEntity,
  importLegalEntities,
  getLegalEntitiesOptionsFiltered
};
