import { API_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';

const structureLevelUrl = `${API_URL}structure-levels`;

const moveStructureLevel = (moveData) => RequestHelper.PUT({ url: `${structureLevelUrl}/move`, body: moveData });

export const StructureLevelService = {
  moveStructureLevel
};
