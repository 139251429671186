import { EquipmentView } from 'models';
import { ToolSummary } from 'models/list/ToolSummary';
import { API_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';
import { EquipmentFilter } from '../models/request/list/EquipmentFilter';

const toolUrl = `${API_URL}tools`;

// POST
const filterToolList: (
  params,
  page: number
) => Promise<ToolSummary> = (
  params = {},
  page = 1
) => RequestHelper.POST({ url: `${toolUrl}/filter?page=${page}`, body: params });

const getLeakDetectors: (
  page?: number
) => Promise<ToolSummary> = (
  page = 1
) => RequestHelper.POST({ url: `${toolUrl}/filter?page=${page}&size=500`, body: { activated: true, type: { value: 'LEAK_DETECTOR', label: 'LEAK_DETECTOR' } } });

const getScales: (
  page?: number
) => Promise<ToolSummary> = (
  page = 1
) => RequestHelper.POST({ url: `${toolUrl}/filter?page=${page}&size=500`, body: { activated: true, type: { value: 'SCALE', label: 'SCALE' } } });

const getTool: (
  id: string
) => Promise<EquipmentView> = (
  id
) => RequestHelper.GET({ url: `${toolUrl}/${id}` });

const createTool: (
  tool: EquipmentView
) => Promise<void> = tool => RequestHelper.POST({ url: toolUrl, body: tool });

const updateTool: (
  id: string,
  tool: EquipmentView
) => Promise<void> = (
  id,
  tool
) => RequestHelper.PUT({ url: `${toolUrl}/${id}`, body: tool });

const mergeTool = (hashIdEdited, hashIdMerged) => RequestHelper.POST({ url: `${toolUrl}/${hashIdEdited}/fuse`, body: { otherId: hashIdMerged } });

const importTools = document => RequestHelper.POST({ url: `${toolUrl}/import`, body: document });

const exportTools = (filter: EquipmentFilter) => RequestHelper.POST({ url: `${toolUrl}/export`, body: filter });

const exportToolsAsync = (filter: EquipmentFilter, signal: AbortSignal) => RequestHelper.POST({
  url: `${toolUrl}/export-async`,
  body: filter,
  signal
});

const toggleActivation = (id: string) => RequestHelper.PUT({ url: `${toolUrl}/${id}/toggle-activation` });

export const ToolsService = {
  filterToolList,
  getLeakDetectors,
  getScales,
  getTool,
  mergeTool,
  createTool,
  updateTool,
  importTools,
  exportTools,
  exportToolsAsync,
  toggleActivation
};
