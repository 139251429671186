import {
  EntitySummary,
  EntitySummaryItem,
  EntityView,
  EquipmentSummary,
  EquipmentSummaryItem,
  LegalEntityFilter
} from 'models';
import { RefrigerationUnitFilter } from 'models/request/list/RefrigerationUnitFilter';
import { API_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';

const equipmentOwnersUrl = `${API_URL}equipment-owners`;

// GET
const getEquipmentOwnerDetail: (id: string) => Promise<EntityView> = (id) => RequestHelper.GET({ url: `${equipmentOwnersUrl}/${id}` });

// POST
const getEquipmentOwnerList: (
  filter: LegalEntityFilter,
  page: number
) => Promise<EntitySummary> = (
  filter = {},
  page = 1
) => RequestHelper.POST({ url: `${equipmentOwnersUrl}/filter?page=${page}`, body: filter });

const getEquipmentOwnerListFull: (
  filter: LegalEntityFilter
) => Promise<EntitySummaryItem[]> = (
  filter = {}
) => RequestHelper.POST({ url: `${equipmentOwnersUrl}/extended-options`, body: filter });

const getEquipmentTotalListSize: (
    filter: LegalEntityFilter
) => Promise<Number> = (
    filter = {}
) => RequestHelper.POST({ url: `${equipmentOwnersUrl}/equipments/count`, body: filter });

const getEquipmentList: (
  ownerId: string,
  filter: RefrigerationUnitFilter,
  page: number
) => Promise<EquipmentSummary> = (
  ownerId,
  filter = {},
  page = 1
) => RequestHelper.POST({ url: `${equipmentOwnersUrl}/${ownerId}/equipments?page=${page}`, body: filter });

const getEquipmentListFull: (
  ownerId: string,
  filter: RefrigerationUnitFilter
) => Promise<EquipmentSummaryItem[]> = (
  ownerId,
  filter = {}
) => RequestHelper.POST({ url: `${equipmentOwnersUrl}/${ownerId}/equipment-list`, body: filter });

const createEquipmentOwner: (
  form: EntityView
) => Promise<void> = form => RequestHelper.POST({ url: `${equipmentOwnersUrl}`, body: form });

const updateEquipmentOwner: (
  hashId: string,
  form: EntityView
) => Promise<void> = (
  hashId,
  form
) => RequestHelper.PUT({ url: `${equipmentOwnersUrl}/${hashId}`, body: form });

const mergeEquipmentOwner = (hashIdEdited, hashIdMerged) => RequestHelper.POST({ url: `${equipmentOwnersUrl}/${hashIdEdited}/fuse`, body: { otherId: hashIdMerged } });

const exportEquipmentsFromLegalEntities = (filter: LegalEntityFilter) => RequestHelper.POST({ url: `${equipmentOwnersUrl}/equipments/export`, body: filter });

const exportAsyncEquipmentsFromLegalEntities = (filter: LegalEntityFilter, signal: AbortSignal) => RequestHelper.POST({
 url: `${equipmentOwnersUrl}/equipments/notif-export`, body: filter, headers: undefined, signal
});

const exportEquipmentsFromALegalEntity = (id: string, filter: RefrigerationUnitFilter) => RequestHelper.POST({ url: `${equipmentOwnersUrl}/${id}/export`, body: filter });

export const EquipmentOwnersService = {
  createEquipmentOwner,
  getEquipmentList,
  getEquipmentListFull,
  getEquipmentOwnerDetail,
  getEquipmentOwnerList,
  getEquipmentOwnerListFull,
  getEquipmentTotalListSize,
  updateEquipmentOwner,
  mergeEquipmentOwner,
  exportEquipmentsFromLegalEntities,
  exportAsyncEquipmentsFromLegalEntities,
  exportEquipmentsFromALegalEntity
};
