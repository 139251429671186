import { InstitutionView } from 'models';
import { API_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';

const institutionsUrl = `${API_URL}institutions`;

const getInstitutionsTrackDechets = freeSearch => RequestHelper.GET({ url: `${institutionsUrl}/track-dechets?freeSearch=${freeSearch}` });

const getInstitutionModel = (institutionUuid) => RequestHelper.GET({ url: `${institutionsUrl}/${institutionUuid}` });
const getInstitutionTree = (institutionUuid, activity) => RequestHelper.GET({ url: `${institutionsUrl}/${institutionUuid}/tree?activity=${activity}` });
// POST
const getInstitutionsList: (
  params,
  page: number
) => Promise<any> = (
  params = {},
  page = 1
) => RequestHelper.POST({ url: `${institutionsUrl}/filter?page=${page}`, body: params });

const getInstitutionsOptions = (params = {}) => RequestHelper.POST({ url: `${institutionsUrl}/options`, body: params });

const createInstitution = institution => RequestHelper.POST({ url: institutionsUrl, body: institution });

const updateInstitution = (id: string, institution: InstitutionView) => RequestHelper.PUT({ url: `${institutionsUrl}/${id}`, body: institution });
const deleteInstitution = (id: string) => RequestHelper.DELETE({ url: `${institutionsUrl}/${id}` });

export const InstitutionsService = {
  getInstitutionsTrackDechets,
  getInstitutionsList,
  getInstitutionsOptions,
  createInstitution,
  updateInstitution,
  deleteInstitution,
  getInstitutionModel,
  getInstitutionTree
};
